<template>
<article class="section">
<div class="container">
    <header class="block">
        <h1 class="title">Data explorer</h1>
    </header>
    <section class="block">
        <form id="search-form" @submit.prevent="search">
            <b-field label="Organisatie">
                <b-autocomplete
                    v-model="fields.organisation_name"
                    :data="organisationAutocomplete"
                    field="name"
                    clearable
                    :loading="isLoadingOrganisationAutocomplete"
                    @typing="handleOrganisationAutocomplete"
                    @select="option => fields.organisation_id = option ? option.id : null"
                ></b-autocomplete>
            </b-field>
            <b-field label="ID">
                <b-input v-model="fields.id"></b-input>
            </b-field>
            <b-field label="Ordernummer">
                <b-input v-model="fields.order_number"></b-input>
            </b-field>
            <b-field label="Trackingnummer">
                <b-input v-model="fields.tracking_number"></b-input>
            </b-field>
            <b-field label="Vervoerder">
                <b-select v-model="fields.service_class">
                    <option :value="null">—</option>
                    <option
                        v-for="option in courierMeta"
                        :value="option.class"
                        :key="option.class">
                        {{ option.name }}
                    </option>
                </b-select>
            </b-field>
            <b-field label="Shop">
                <b-select v-model="fields.service_class">
                    <option :value="null">—</option>
                    <option
                        v-for="option in shopMeta"
                        :value="option.class"
                        :key="option.class">
                        {{ option.name }}
                    </option>
                </b-select>
            </b-field>
            <b-field label="type">
                <b-radio-button v-model="fields.service_item" native-value="order">
                    Order
                </b-radio-button>
                <b-radio-button v-model="fields.service_item" native-value="shipment">
                    Verzending
                </b-radio-button>
                <b-radio-button v-model="fields.service_item" native-value="label">
                    Label
                </b-radio-button>
                <b-radio-button v-model="fields.service_item" native-value="shop">
                    Shop
                </b-radio-button>
                <b-radio-button v-model="fields.service_item" native-value="courier">
                    Vervoerder
                </b-radio-button>
            </b-field>
            <div class="field">
                <div class="buttons is-right">
                    <b-button type="is-primary" native-type="submit" :loading="isLoading">Zoeken</b-button>
                </div>
            </div>
        </form>
    </section>
    <section v-if="meta.type === 'serviceItem'" class="block">
        <b-table
            key="serviceItem"
            :data="results"
            :striped="true"
            :hoverable="true"
            :loading="isLoading"
            :mobile-cards="false"

            paginated
            backend-pagination
            :total="meta.total"
            :per-page="meta.page_size"
            @page-change="handlePageChange"
            :pagination-simple="true"
        >
            <b-table-column field="type" label="Type" cell-class="is-vcentered" v-slot="props">
                {{ props.row.type|formatType }}
            </b-table-column>
            <b-table-column field="organisation_id" label="Organisatie" cell-class="is-vcentered" v-slot="props">
                <v-with :organisation="getOrganisation(props.row.organisation_id)" v-slot="{organisation}">
                    <div>
                        <template v-if="organisation">{{ organisation.name }}</template>
                        <b-skeleton :animated="true" :active="props.row.organisation_id && !organisation"></b-skeleton>
                    </div>
                </v-with>
            </b-table-column>
            <b-table-column field="service_class" label="Integratie" cell-class="is-vcentered" v-slot="props">
                <v-with :courier="getCourier(props.row.service_id)" v-slot="{courier}">
                    <template v-if="courier">{{ courier.service_name }}</template>
                    <template v-else>{{ props.row.service_class }}</template>
                </v-with>
            </b-table-column>
            <b-table-column field="created_at" label="Gemaakt" cell-class="is-vcentered" v-slot="props">
                {{ props.row.created_at|formatTimestamp }}
            </b-table-column>
            <b-table-column field="status" label="Status" cell-class="is-vcentered" v-slot="props">
                {{ props.row.status|formatStatus }}
                <span v-if="!props.row.is_active" class="tag is-danger">
                    Verwijderd
                </span>
            </b-table-column>
            <b-table-column field="order_number" label="Ordernummer" cell-class="is-vcentered" v-slot="props">
                {{ props.row.order_number }}
            </b-table-column>
            <b-table-column field="tracking_number" label="Trackingnummer" cell-class="is-vcentered" v-slot="props">
                {{ props.row.tracking_number }}
            </b-table-column>
            <b-table-column field="invoice_id" label="Facturen" cell-class="is-vcentered" v-slot="props">
                <template v-if="props.row.invoice_items">
                    <v-with
                        v-for="invoiceItem, idx in props.row.invoice_items.filter(i => i.invoice_id)"
                        :key="idx"
                        :invoice="getInvoice(invoiceItem.invoice_id)"
                        v-slot="{invoice}"
                    >
                        <span>
                            <template v-if="invoice">
                                {{ invoice.invoice_number }}
                            </template>
                            <b-skeleton :animated="true" :active="!!invoiceItem.invoice_id && !invoice"></b-skeleton>
                        </span>
                    </v-with>
                </template>
            </b-table-column>
            <b-table-column cell-class="is-vcentered buttons is-right" v-slot="props">
                <b-button
                    size="is-small"
                    @click="currentItem = props.row"
                >
                    <span class="icon is-small">
                        <i class="gg-eye" aria-hidden="true"></i>
                        <span class="is-sr-only">Details</span>
                    </span>
                </b-button>
            </b-table-column>

            <template #empty>
                <div class="has-text-centered">Geen resultaten</div>
            </template>
        </b-table>
    </section>
    <section v-else class="block">
        <b-table
            key="service"
            :data="results"
            :striped="true"
            :hoverable="true"
            :loading="isLoading"
            :mobile-cards="false"

            paginated
            backend-pagination
            :total="meta.total"
            :per-page="meta.page_size"
            @page-change="handlePageChange"
            :pagination-simple="true"
        >
            <b-table-column field="type" label="Type" cell-class="is-vcentered" v-slot="props">
                {{ props.row.type|formatType }}
            </b-table-column>
            <b-table-column field="service_class" label="Integratie" cell-class="is-vcentered" v-slot="props">
                {{ props.row.service_name || props.row.service_class }}
            </b-table-column>
            <b-table-column field="created_at" label="Actief sinds" cell-class="is-vcentered" v-slot="props">
                {{ props.row.created_at|formatTimestamp }}
            </b-table-column>
            <b-table-column field="status" label="Status" cell-class="is-vcentered" v-slot="props">
                <span v-if="props.row.status === 'success'">
                    Koppeling succesvol
                </span>
                <span v-else-if="props.row.credential_status === 'invalid'">
                    <b-icon
                        icon="close-o"
                        type="is-danger"
                    ></b-icon>
                    Ongeldige inloggegevens
                </span>
                <span v-else-if="props.row.status === 'error'">
                    Fout tijdens koppelen
                </span>
                <span v-else>
                    Onbekend
                </span>
                <span v-if="!props.row.is_active" class="tag is-danger">
                    Verwijderd
                </span>
            </b-table-column>
            <b-table-column field="organisation_id" label="Organisatie" cell-class="is-vcentered" v-slot="props">
                <v-with :organisation="getOrganisation(props.row.organisation_id)" v-slot="{organisation}">
                    <div>
                        <template v-if="organisation">{{ organisation.name }}</template>
                        <b-skeleton :animated="true" :active="props.row.organisation_id && !organisation"></b-skeleton>
                    </div>
                </v-with>
            </b-table-column>
            <b-table-column field="organisation_id" label="E-mail" cell-class="is-vcentered" v-slot="props">
                <v-with :organisation="getOrganisation(props.row.organisation_id)" v-slot="{organisation}">
                    <div>
                        <template v-if="organisation && organisation.contact">{{ organisation.contact.email }}</template>
                        <b-skeleton :animated="true" :active="props.row.organisation_id && !organisation"></b-skeleton>
                    </div>
                </v-with>
            </b-table-column>

            <template #empty>
                <div class="has-text-centered">Geen resultaten</div>
            </template>
        </b-table>
    </section>
</div>
<b-modal
    v-model="hasCurrentItem"
    has-modal-card
    trap-focus
    :destroy-on-hide="false"
    aria-role="dialog"
    aria-label="Example Modal"
    aria-modal
    @close="currentItem = null"
>
    <template #default="props">
        <div class="modal-card" v-if="currentItem">
            <header class="modal-card-head">
                <p class="modal-card-title">{{ currentItem.type|formatType }}</p>
                <button type="button" class="delete" @click="props.close"></button>
            </header>
            <section class="modal-card-body">
                <table class="table is-fullwidth">
                    <tbody>
                        
                    <tr>
                        <th>Organisatie</th>
                        <td>
                            <v-with :organisation="getOrganisation(currentItem.organisation_id)" v-slot="{organisation}">
                                <div>
                                    <template v-if="organisation">{{ organisation.name }}</template>
                                    <b-skeleton :animated="true" :active="!!currentItem.organisation_id && !organisation"></b-skeleton>
                                </div>
                            </v-with>
                        </td>
                    </tr>
                    <tr>
                        <th>Integratie</th>
                        <td>
                            <v-with :courier="getCourier(currentItem.service_id)" v-slot="{courier}">
                                <div>
                                    <template v-if="courier">{{ courier.service_name }}</template>
                                    <template v-else>{{ currentItem.service_class }}</template>
                                    <b-skeleton :animated="true" :active="!!currentItem.service_id && !courier"></b-skeleton>
                                </div>
                            </v-with>
                        </td>
                    </tr>
                    <tr>
                        <th>Gemaakt</th>
                        <td>{{ currentItem.created_at|formatTimestamp }}</td>
                    </tr>
                    <tr>
                        <th>Status</th>
                        <td>
                            {{ currentItem.status|formatStatus }}
                            <span v-if="!currentItem.is_active" class="tag is-danger">
                                Verwijderd
                            </span>
                        </td>
                    </tr>
                    <tr v-if="currentItem.type === 'order'">
                        <th>Ordernummer</th>
                        <td>{{ currentItem.order_number }}</td>
                    </tr>
                    <tr v-if="currentItem.type === 'order'">
                        <th>Orderdatum</th>
                        <td>{{ currentItem.order_date|formatTimestamp('YYYY-MM-DD') }}</td>
                    </tr>
                    <tr v-if="currentItem.type === 'order'">
                        <th>
                            Shop status
                        </th>
                        <td>
                            {{ currentItem.order_status}}
                        </td>
                    </tr>
                    <tr v-if="currentItem.type === 'label' || currentItem.type === 'shipment'">
                        <th>Verzendmethode</th>
                        <td>
                            <v-with :courier="getCourier(currentItem.service_id)" v-slot="{courier}">
                                <template v-if="courier">
                                    {{ getShipmentType(courier, currentItem.shipment_type) }}
                                </template>
                                <template v-else>
                                    {{ currentItem.shipment_type }}
                                </template>
                            </v-with>
                            <span v-if="currentItem.is_return" class="tag">
                                Retour
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <th>Land</th>
                        <td><country v-model="currentItem.country"></country></td>
                    </tr>
                    <tr v-if="currentItem.type === 'label'">
                        <th>Trackingnummer</th>
                        <td>{{ currentItem.tracking_number }}</td>
                    </tr>
                    <tr v-if="currentItem.type === 'label'">
                        <th>Trackingdatum</th>
                        <td>{{ currentItem.tracking_date|formatTimestamp('YYYY-MM-DD') }}</td>
                    </tr>
                    <tr v-if="currentItem.type === 'label'">
                        <th>
                            Facturen
                        </th>
                        <td>
                            <template v-if="currentItem.invoice_items">
                                <v-with
                                    v-for="invoiceItem, idx in currentItem.invoice_items.filter(i => i.invoice_id)"
                                    :key="idx"
                                    :invoice="getInvoice(invoiceItem.invoice_id)"
                                    v-slot="{invoice}"
                                >
                                    <div>
                                        <template v-if="invoice">
                                            {{ invoice.invoice_number }}
                                        </template>
                                        <b-skeleton :animated="true" :active="!!invoiceItem.invoice_id && !invoice"></b-skeleton>
                                    </div>
                                </v-with>
                                <b-button
                                    v-if="currentItem.invoice_items.some(i => i.invoice_id === false)"
                                    @click="submitToggleLabel(currentItem)"
                                >
                                    Facturatie aanzetten   
                                </b-button>
                            </template>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </section>
            <footer class="modal-card-foot buttons is-right">
                <b-button type="is-primary" @click="props.close">OK</b-button>
            </footer>
        </div>
    </template>
</b-modal>
</article>
</template>

<script>
import { mapState } from 'vuex';
import { api } from '@/utils';
import { debounce } from '@/utils/functions';
import Country from '@/components/Country';


export default {
    components: {Country},
    data() {
        return {
            fields: {
                service_item: 'label',
                organisation_name: null,
                organisation_id: null,
                id: null,
                order_number: null,
                tracking_number: null,
                service_class: null,
            },
            errors: {
                service_item: [],
                organisation_name: [],
                organisation_id: [],
                id: [],
                order_number: [],
                tracking_number: [],
                service_class: [],
            },
            results: [],
            meta: {
                type: 'serviceItem',
                total: 0,
                page_size: 25,
                page: 1,
            },
            isLoading: false,
            currentItem: null,
            organisationAutocomplete: [],
            isLoadingOrganisationAutocomplete: false,
        };
    },
    computed: {
        ...mapState({
            organisations: state => state.organisation.all,
            courierMeta: state => state.courier.meta,
            shopMeta: state => state.shop.meta,
        }),
        hasCurrentItem: {
            get() {
                return Boolean(this.currentItem);
            },
            set(value) {
                if (!value)
                    this.currentItem = null;
            },
        },
    },
    methods: {
        async search() {
            const has_input = (
                this.fields.organisation_id !== null
                || this.fields.id !== null
                || this.fields.order_number !== null
                || this.fields.tracking_number !== null
                || this.fields.service_class !== null
            )

            if (!has_input)
                return;

            this.isLoading = true;
            try {
                let result;
                if (this.fields.service_item === 'shop' || this.fields.service_item === 'courier') {
                    this.meta.type = 'service';
                    result = await api.getServices(
                        this.fields.service_item,
                        {
                            ...this.fields,
                            page: this.meta.page,
                        }
                    );
                } else {
                    this.meta.type = 'serviceItem';
                    result = await api.searchServiceItems({
                        ...this.fields,
                        page: this.meta.page,
                    });
                }
                this.results = result.data; 
                for (let key in result.meta)
                    this.meta[key] = result.meta[key];
                this.loadCouriers(); // No await, this can happen in background
                this.loadInvoices(); // No await, this can happen in background
                this.loadOrganisations(); // No await, this can happen in background
            } catch (e) {
                if (e.response && e.response.type === 'validation_error') {
                    let errors = e.response.data;

                    for (const field in errors)
                        this.errors[field] = this.errors[field].concat(errors[field]);
                }                
            } finally {
                this.isLoading = false;
            }
        },
        getCourier(courierId) {
            return this.$store.state.courier.all.find(o => o.id === courierId);
        },
        getInvoice(invoiceId) {
            return this.$store.state.invoice.all.find(o => o.id === invoiceId);
        },
        getOrganisation(organisationId) {
            return this.$store.state.organisation.all.find(o => o.id === organisationId);
        },
        getShipmentType (courier, shipmentType) {
            const type = courier.shipment_types.find(t => t.id === shipmentType);
            return type?.name || 'Standaard';
        },
        handlePageChange(page) {
            this.meta.page = page;
            this.search();
        },
        async loadCouriers() {
            // async so this call can run in the background
            const ids = [...new Set(this.results
                .filter(o => o.service_id && !this.getCourier(o.service_id))
                .map(o => o.service_id)
            )];
            if (ids.length > 0) {
                await this.$store.dispatch('courier/getCouriers', {ids});
            }
        },
        async loadInvoices() {
            // async so this call can run in the background
            const ids = [...new Set(this.results
                .map(i => i.invoice_items || [])
                .flat()
                .filter(i => i.invoice_id && !this.getInvoice(i.invoice_id))
                .map(i => i.invoice_id)
            )];
            if (ids.length > 0)
                await this.$store.dispatch('invoice/loadView', {filter: {ids}});
        },
        async loadOrganisations() {
            // async so this call can run in the background
            const ids = [...new Set(this.results
                .filter(i => i.organisation_id && !this.getOrganisation(i.organisation_id))
                .map(i => i.organisation_id)
            )];
            if (ids.length > 0)
                await this.$store.dispatch('organisation/loadView', {filter: {ids}});
        },
        async submitToggleLabel(label) {
            let updates = {enable_invoice_items: true};
            const result = await api.updateLabel(label.id, updates);
            const idx = this.results.indexOf(label);
            if (idx >= 0)
                this.results.splice(idx, 1, result.data);
            else
                this.results.push(result.data);
            // Also update currentItem if applicable
            if (label === this.currentItem)
                this.currentItem = result.data;
        },
        handleOrganisationAutocomplete: debounce(async function (name) {
            if (!name.length) {
                this.organisationAutocomplete = [];
            } else {
                this.isLoadingOrganisationAutocomplete = true;
                const result = await this.$store.dispatch('organisation/loadView', {filter: {autocomplete: name}});
                this.organisationAutocomplete = result?.data || [];
                this.isLoadingOrganisationAutocomplete = false;
            }
        }, 300),
    },
    filters: {
        formatType(type) {
            if (type === 'label')
                return 'Label';
            else if (type === 'order')
                return 'Order';
            else if (type === 'shipment')
                return 'Verzending';
            else if (type === 'shop')
                return 'Shop';
            else if (type === 'courier')
                return 'Vervoerder';
            else
                return 'Onbekend';
        },
        formatStatus (status) {
            const mapping = {
                'pending': 'Nog niet zichtbaar',
                'blocked': 'Land niet ondersteund',
                'incomplete': 'Niet volledig',
                'ready': 'Klaar voor verwerken',
                'label': 'Niet geprint',
                'printed': 'Geprint',
                'transit': 'Onderweg naar sorteercentrum',
                'depot': 'In sorteercentrum',
                'courier': 'Chauffeur is onderweg',
                'delivered': 'Bezorgd',
            };

            const s = (new String(status)).toLowerCase();

            if (mapping[s])
                return mapping[s];
            else if (status)
                return status;
            return 'Onbekend';
        },
    },
    created() {
        this.$store.dispatch('courier/getMeta');
        this.$store.dispatch('shop/getMeta');
    },
};
</script>
